/* .table-container{
    padding:10px;
}

.table {
    width: 100%;
    border-collapse: collaps|e;
    border-spacing:0 10px
}

.table-header{
   display: table;
}

.tr-header {
    padding: 10px;
    color: #414042B2; 
}

.table-body:nth-child(even) {
    background-color: rgba(196, 196, 196, 0.2);
} */


.tr_body_style:nth-child(even){
    background-color: rgba(196, 196, 196, 0.2);
}

.td_body_style{
    min-height: 50px;
    padding:10px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

/* utility */
thead > tr > td > div {
    padding:10px
}

.form_control{
    display: none;
}


.form_control__search_input {
    background-color: #F7F7F7;
    border: none;
    border-radius: 10px;
    
    width: 100%;
    height: 40px;
    margin-right: 5%;
}

.form_control__icon{
    height: 100%;
    display: flex;
    align-items: center;

    position: absolute;
    right: 5%;
    
    z-index: 2;
}

@media (max-width: 768px) and (min-width:320px){
    .table_container{
        border-collapse: collapse;
        overflow-x: auto;
    }

    .form_control{
        display: block;
    }
}