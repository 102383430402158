.layout {
    width: 100%;
    min-height: 100vh;
    background: url('./background-antrian.jpg') no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 36px;
}

.layout__content{
    margin:5%;
    display: grid;
    gap: 50px;
    grid-template-columns: repeat(3, 1fr) !important;
}

.layout__content__poly{
    min-width: 300px;
    min-height: 200px;
    padding: 0 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0px 0px 25px 3px rgba(0, 0, 0, 0.15);
    background-color: rgba(255, 255, 255, .9);
    border-radius: 10px;
    
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
      
    
    color: #219BD4;
    cursor: pointer;
    font-size: .9em;
    font-weight: 700;

    transition: all linear 50ms;
}

.layout__content__poly:active{
    transform: scale(.9);
}

@media(max-width:1024px){
    .layout__content{
        display: grid;
        gap: 50px;
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

@media(max-width:768px){
    .layout__content{
        gap: 25px;
        grid-template-columns: repeat(2, 1fr)!important;
    }
}

@media (max-width:640px){
    .layout__content{
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

/* @media(max-width:375px){
    .layout__content{
        width: 85%;
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

@media(max-width:320px){
    .layout__content{

        grid-template-columns: repeat(1, 1fr) !important;
    }

    .layout__content__poly{
        min-width: 230px;
        min-height: 200px;
    }

    .layout > h1 {
        font-size: 3rem !important;
    }
} */
