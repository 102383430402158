.icon-menu {
    background: url('./icons/Menu-Full.svg') no-repeat;
    background-size: contain;
    margin: 5px 15px;
}

.icon-bpjs {
    background: url('./icons/BPJS-full.svg') no-repeat;
    background-size: contain;
    margin: 5px 15px;
}

.icon-notif {
    background: url('./icons/Notification-Full.svg') no-repeat;
    background-size: contain;
    margin: 5px 15px;
}

.icon-report {
    background: url('./icons/Laporan-full.svg') no-repeat;
    background-size: contain;
    margin: 5px 15px;
}

.icon-inventory {
    background: url('./icons/Inventory-full.svg') no-repeat;
    background-size: contain;
    margin: 5px 15px;
}



/* .ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
    height: 60px;
    line-height: 40px;
}

.ant-menu-item .ant-menu-item-selected{
    padding-top:7px;
} */

/* icon menu when sidebar is collaps */
/* #root > div > section > aside > div.ant-layout-sider-children > ul > li.ant-menu-item.ant-menu-item-selected{
    padding-top:7px;
    padding-bottom:10px;
    
} */


/* .ant-menu-item .ant-menu-item-selected {
    padding:0px;
} */
