.layout {
    background-color: #FFFFFF;
    height: 100vh;
    display: flex;
}

.layout__left {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.layout__right {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

}

.layout__right__container {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.layout__right__container__background{
    display: none;
    position: absolute;
}

.layout__right__container__body {
    width: 280px;
}

.layout__right__container__body > h1 {
    font-weight: 700;
    color: #21D47E;
    margin: 40px 0;
    text-align: center;
}

.layout__right__container__form__label{
    text-align: center;
}

.layout__right__logo{
    background: url('../header/medisy.png') no-repeat;
    background-size: contain;
    width: 500px;
    padding: 10px;
    margin: 10px 10px 10px 10px ;
    float: right;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.authInput{
    width: 280px;
    height: 40px;
    border-radius: 10px;
    border: 1px solid rgba(20, 155, 212, 0.5);
}
.authInput:hover{
    /* width: 280px;
    height: 40px;
    border-radius: 10px; */
    border: 1px solid #40a9ff;
}

.btnPrimary {
    width: 100% !important;
    height: 40px !important;
    margin: 40px 0 !important;
    background-color: #0A7BCD !important;
    box-shadow: 0px 0px 23px -3px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
    font-weight: 700 !important;
    color: #FFFFFF !important;
}

.ant-form-vertical .ant-form-item {
    flex-direction: row !important;
}


@media(max-width: 768px){
    .layout__left {
        display: none !important;
    }

    .layout__right__container{
        flex-direction: row;
    }

    .layout__right__container__background{
        display:block;
        z-index: 0;
        opacity: .15;
    }
}
