
.circle{
    width:45px;
    height:45px;
    line-height: 25px;
    background-color: #27AAE1;
    border-radius: 50%;
    font-weight: 700;
    font-size: 1.2em;
    color: #FFFFFF;
    border: 2px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.step_wrapper{
    display: flex;
    margin-bottom:20px;
    justify-content: space-between;
    align-items: center;
}

.step_block{
    cursor: pointer;
}

.step_block .circle_wrapper{
    padding-left: 0 20px;
    position: relative;
    display: flex;
    justify-content: center;
}

.selected .circle {
    width: 50px;
    height: 50px;
    color: #FFFFFF;
    background-color: #21D47E;
}

.selected > span {
    font-weight: 700;
}

.divider{
    position: absolute;
    width: 85%;
    height: 3px;
    top: 35%;
    left: 10%;
    background-color: rgba(65, 64, 66, 0.1);
}

/* .step_block .circle_wrapper:after{
    content: "";
    width: 100%;
    height: 50px;
    position:absolute;
    top: 0px;
    bottom: 0px;
    background-color: green;
    margin: auto;
    z-index: 2;
} */

