.layout {
  width: 100%;
  display: flex;
}

.layout__left {
  width: 30%;
  height: 100%;
  padding: 5%;
  background-color: #219bd4;
  color: #ffffff;
}

.layout__left__top {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.layout__left__top > h1 {
  font-size: 6em;
  color: #ffffff;
  margin-bottom: 0.25em;
}

.layout__left__top > h2 {
  color: #ffffff;
}

.layout__left__top > span {
  font-size: 1.2em;
  font-weight: 800;
  color: #ffffff;
}

.layout__left__middle {
  padding: 8em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.layout__left__middle > h3 {
  font-size: 1.75em;
  font-weight: 300;
  color: #ffffff;
}

.layout__left__bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.layout__left__bottom > h3 {
  font-size: 1.4em;
  font-weight: 300;
  color: #ffffff;
}

.layout__left__bottom > span {
  max-width: 135px;
  height: 54px;
  font-size: 2.5em;
  font-weight: 600;
}

.layout__right {
  width: 70%;
}

.layout__right__top {
  width: auto;
  height: 65%;
  background: url("background_klinik.png") no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.layout__right__bottom {
  padding: 3em;
  display: flex;
  justify-content: space-between;
}

.layout__right__bottom__queue_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.layout__right__bottom__queue_container__order_number {
  width: 50px;
  height: 50px;
  background-color: #219bd4;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 1.75em;
  font-weight: 700;
}

.layout__right__bottom__queue_container__queue_number {
  font-size: 3em;
  font-weight: 600;
}

.layout__right__bottom__queue_container__name {
  font-size: 1.3em;
  font-weight: 500;
}
