@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Quicksand', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center !important;
}

.p-standard {
  padding: 10px 20px;
}

.title-card {
  font-weight: 600;
  color: #1890ff;
}

/* handle offside width at sider trigger */
.ant-layout-sider-trigger{
  width: 0px !important;
}

/* handle padding top data menu on sidebar */
.ant-layout-sider-children{
padding-top:10px !important;
}

/* handle font size in description */
.ant-descriptions .ant-descriptions-bordered .cus-description{
  font-size: 1.1em !important;
}
