@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: 'Quicksand', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center !important;
}

.p-standard {
  padding: 10px 20px;
}

.title-card {
  font-weight: 600;
  color: #1890ff;
}

/* handle offside width at sider trigger */
.ant-layout-sider-trigger{
  width: 0px !important;
}

/* handle padding top data menu on sidebar */
.ant-layout-sider-children{
padding-top:10px !important;
}

/* handle font size in description */
.ant-descriptions .ant-descriptions-bordered .cus-description{
  font-size: 1.1em !important;
}

.top-bar-background {
  background: #fff;
}

.logo {
  height: 70%;
  background: url(/static/media/medisy.29ddaeea.png) no-repeat;
  background-size: contain;
  width: 12%;
  padding: 10px;
  margin: 10px 10px 10px 10px;
  float: right;
}

.menu {
  margin-left: 3%;
  width: 100%;
  /* background: #666; */
  float: left;
}

.data-table {
  margin: 0 auto;
  padding: 0;
}

.form-control {
  border: 1px solid rgba(20, 155, 212, 0.5);
  border-radius: 0.5em;
}

.lable-form-input {
  font-weight: bold;
}

.form-control-currency {
  width: 100%;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid rgba(20, 155, 212, 0.5);
}

.form-control-currency:focus {
  border-color: rgba(20, 155, 212, 0.5);
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.card-display {
  border-radius: 10px;
  margin: 10px;
}

.text-dashboard {
  font-size: 2em;
  text-align: center;
  margin-left: 50%;
}

.dividing-line {
  border: 1px solid #cacaca;
  background-color: #cacaca;
}

/* Start of Button Style */
.btn-primary {
  /* margin:5px; */
  padding: 0 25px;
  background: #27aae1;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  color: #ffff;
  border-color: transparent;
}
.btn-primary:hover {
  background: #27aae1;
  color: #ffff;
  opacity: 0.75;
  border-color: transparent;
}

.btn-primary:focus {
  background: #27aae1;
  color: #ffff;
  opacity: 0.75;
  border-color: transparent;
}

.btn-secondary {
  /* margin:5px; */
  padding: 0 25px;
  background: #21d47e;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  color: #ffff;
  border-color: transparent;
}
.btn-secondary:hover {
  background: #21d47e;
  color: #ffff;
  opacity: 0.75;
  border-color: transparent;
}

.btn-secondary:focus {
  background: #21d47e;
  color: #ffff;
  opacity: 0.75;
  border-color: transparent;
}

.btn-light {
  /* margin:5px; */
  padding: 0 25px;
  background: #ffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  color: #27aae1;
  border-color: transparent;
}
.btn-light:hover {
  background: #ffff;
  color: #27aae1;
  opacity: 0.75;
  border-color: transparent;
}

.btn-light:focus {
  background: #ffff;
  color: #27aae1;
  opacity: 0.75;
  border-color: transparent;
}

.btn-danger {
  /* margin:5px; */
  padding: 0 25px;
  background: #ff4d4f;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  color: #fff;
  border-color: transparent;
}
.btn-danger:hover {
  background: #ff7875;
  color: #fff;
  /* #d9363e */
  border-color: transparent;
}

.btn-danger:active {
  background: #d9363e;
  color: #fff;
  border-color: transparent;
}

.btn-danger:focus {
  background: #ff4d4f;
  color: #fff;
  opacity: 0.75;
  border-color: transparent;
}
/* End of Button Style */

/* start style of search box and add patient button */

.form-control__icon {
  height: 100%;
  display: flex;
  align-items: center;

  position: absolute;
  right: 5%;

  z-index: 2;
}

.form-control__search-input {
  background-color: #f7f7f7;
  border: none !important;
  border-radius: 10px;

  width: 200px;
  height: 40px;
  margin-right: 5%;
}

/* navbar */
.top_navbar_container {
  width: 100%;
  padding: 0 3%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top_navbar_container__left {
  width: 70%;
  display: flex;
  align-items: center;
}

.top_navbar_container__left_menu {
  display: none;
}

@media (max-width: 768px) and (min-width: 320px) {
  /* .form-control{
        display: none;
    } */

  .top_navbar_container__left_menu {
    display: block;
  }
}

/* end style of search box and add patient button */

/* when text input is hovering */
/* .ant-input:hover{
  border-color:rgba(0, 0, 0, 0);
} */
/* 
.ant-input:focus{
  border-color:rgba(0, 0, 0, 0.15);
} */

/* Customize selector antd (border within borders)*/
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #ffffff;
  border-radius: 7px;
}

/* when select is hovering*/
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #ffffff;
}

/* when select is in focus */
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #ffffff;
  /* border-radius: 7px; */
}

/*
  * Start style for description
  */
.cus-description {
  width: 100%;
  margin-bottom: 20px;
}

.cus-description .ant-descriptions-view {
  border: unset !important;
}

.cus-description .ant-descriptions-view .ant-descriptions-row {
  border-bottom: unset !important;
}

.cus-description.ant-descriptions-bordered .ant-descriptions-item-label,
.cus-description.ant-descriptions-bordered .ant-descriptions-item-content {
  background-color: #fff !important;
  border-right: unset !important;
  padding: 10px 10px !important;
}

.cus-description.ant-descriptions-bordered .ant-descriptions-item-label {
  color: #414042f7 !important;
  font-size: 0.9em;
}

.cus-description.ant-descriptions-bordered .ant-descriptions-item-content {
  font-size: 1em;
  font-weight: 700;
}
/*
  * End style for description
  */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d1d1d1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a5a5a5;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d2d2d2;
}

.data_sidebar_container {
  display: flex;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.data_sidebar_container:hover {
  background-color: #219bd433;
}

.data_sidebar_container_active {
  background-color: #219bd433;
  display: flex;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.data_sidebar_container__icon {
  margin: 5px;
}

.data_sidebar_container > strong {
  padding-left: 10px;
  padding-right: 10px;
}

.data_sidebar_mobile_container {
  display: flex;
  align-items: center;
  margin: 5%;
  cursor: "pointer";
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.data_sidebar_mobile_container:hover {
  background-color: #219bd433;
}

.data_sidebar_mobile_container_active {
  display: flex;
  align-items: center;
  margin: 5%;
  cursor: "pointer";
  background-color: #219bd433;
}

@media (max-width: 1024px) {
  .ant-form-item-label > label {
    font-size: 12px;
  }

  .ant-select {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  /* Sidebar customize */
  .ant-layout-sider {
    display: none;
  }
}

@media (min-width: 1281px) {

  
  .btn-search-full {
    position: absolute;
    bottom: 70px;
    height: 32px;
    width: 20%;
    right: 50px;
  }

  .btn-search-full2 {
    position: absolute;
    bottom: 75px;
    height: 32px;
    width: 20%;
    right: 0;
    background-color: #21d47e;
  }

  .btn-search-full3 {
    position: absolute;
    bottom: 140px;
    height: 32px;
    width: 30%;
    right: 30px;
  }

  .data-table2 {
    bottom: 0.5vh;
  }

  .data-table {
    bottom: 6vh;
  }
}

.filter-data-table {
  width: 20%;
}
.icon-menu {
    background: url(/static/media/Menu-Full.3fd091b5.svg) no-repeat;
    background-size: contain;
    margin: 5px 15px;
}

.icon-bpjs {
    background: url(/static/media/BPJS-full.61d083c7.svg) no-repeat;
    background-size: contain;
    margin: 5px 15px;
}

.icon-notif {
    background: url(/static/media/Notification-Full.52c4ec2d.svg) no-repeat;
    background-size: contain;
    margin: 5px 15px;
}

.icon-report {
    background: url(/static/media/Laporan-full.05cb4a05.svg) no-repeat;
    background-size: contain;
    margin: 5px 15px;
}

.icon-inventory {
    background: url(/static/media/Inventory-full.3ab422a0.svg) no-repeat;
    background-size: contain;
    margin: 5px 15px;
}



/* .ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
    height: 60px;
    line-height: 40px;
}

.ant-menu-item .ant-menu-item-selected{
    padding-top:7px;
} */

/* icon menu when sidebar is collaps */
/* #root > div > section > aside > div.ant-layout-sider-children > ul > li.ant-menu-item.ant-menu-item-selected{
    padding-top:7px;
    padding-bottom:10px;
    
} */


/* .ant-menu-item .ant-menu-item-selected {
    padding:0px;
} */

 table{
     width: 100%;
 }
.dataTable_tr_body_style__39o2J:nth-child(even){
    background-color: rgba(196, 196, 196, 0.2);
}

.dataTable_td_body_style__3P409{
    min-height: 50px;
    padding:10px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

/* utility */
thead > tr > td > div {
    padding:10px
}

.dataTable_form_control__1dF5H{
    width: 30%;
}


.dataTable_form_control__search_input__30yUN {
    background-color: #F7F7F7;
    border: none;
    border-radius: 10px;
    
    width: 100%;
    height: 40px;
    margin-right: 0%;
    padding-right: 10%;
}

.dataTable_form_control__icon__LtJxg{
    height: 100%;
    display: flex;
    align-items: center;

    position: absolute;
    right: 3%;
    
    z-index: 2;
}

.dataTable_footer_container__2JDHk{
    padding:1%;
    display: flex;
    justify-content: space-between;
}

.dataTable_select_section__3BK3D {
    display: flex;
    justify-content:center;
}

.dataTable_select_limit__AFP0d{
    display: flex;
    align-items: center;


    margin: 0 15px;
    
}

.dataTable_select_limit__AFP0d > select {
    border:none;
    border-radius: 5px;
    background-color: #f3f3f3;
    margin: 0 1%;
    padding:5px;
    height: 35px;
    outline-color: #1890ff

}

.dataTable_select_limit__AFP0d > p {
    margin:0;
}

.dataTable_container_pagination__2-eEq{
    width:30%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    width:25%;
    font-size:1.1em;
    list-style: none;
}
.dataTable_container_pagination__2-eEq > li {
    padding: 0 10px;
    margin: 5px 10px;
}

.dataTable_container_pagination__2-eEq > li > a{
    text-decoration: none;
    color: #000;
    font-size: 1.1em;
}

.dataTable_container_pagination_active__3Ds8H {
    background-color: #f3f3f3;
    border-radius: 10px;
}

@media (max-width: 768px) and (min-width:320px){
    .dataTable_table_container__1XwQM{
        border-collapse: collapse;
        overflow-x: auto;
    }

    .dataTable_form_control__1dF5H{
        display: block;
    }
}
/* .table-container{
    padding:10px;
}

.table {
    width: 100%;
    border-collapse: collaps|e;
    border-spacing:0 10px
}

.table-header{
   display: table;
}

.tr-header {
    padding: 10px;
    color: #414042B2; 
}

.table-body:nth-child(even) {
    background-color: rgba(196, 196, 196, 0.2);
} */


.queue_tr_body_style__1iIfh:nth-child(even){
    background-color: rgba(196, 196, 196, 0.2);
}

.queue_td_body_style__2HWCf{
    min-height: 50px;
    padding:10px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

/* utility */
thead > tr > td > div {
    padding:10px
}

.queue_form_control__kIMcg{
    display: none;
}


.queue_form_control__search_input__27Zig {
    background-color: #F7F7F7;
    border: none;
    border-radius: 10px;
    
    width: 100%;
    height: 40px;
    margin-right: 5%;
}

.queue_form_control__icon__3kNw4{
    height: 100%;
    display: flex;
    align-items: center;

    position: absolute;
    right: 5%;
    
    z-index: 2;
}

@media (max-width: 768px) and (min-width:320px){
    .queue_table_container__b-V2D{
        border-collapse: collapse;
        overflow-x: auto;
    }

    .queue_form_control__kIMcg{
        display: block;
    }
}

.circle{
    width:45px;
    height:45px;
    line-height: 25px;
    background-color: #27AAE1;
    border-radius: 50%;
    font-weight: 700;
    font-size: 1.2em;
    color: #FFFFFF;
    border: 2px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}

.step_wrapper{
    display: flex;
    margin-bottom:20px;
    justify-content: space-between;
    align-items: center;
}

.step_block{
    cursor: pointer;
}

.step_block .circle_wrapper{
    padding-left: 0 20px;
    position: relative;
    display: flex;
    justify-content: center;
}

.selected .circle {
    width: 50px;
    height: 50px;
    color: #FFFFFF;
    background-color: #21D47E;
}

.selected > span {
    font-weight: 700;
}

.divider{
    position: absolute;
    width: 85%;
    height: 3px;
    top: 35%;
    left: 10%;
    background-color: rgba(65, 64, 66, 0.1);
}

/* .step_block .circle_wrapper:after{
    content: "";
    width: 100%;
    height: 50px;
    position:absolute;
    top: 0px;
    bottom: 0px;
    background-color: green;
    margin: auto;
    z-index: 2;
} */


.reg_queue_layout__3p14O {
    width: 100%;
    min-height: 100vh;
    background: url(/static/media/background-antrian.42cf72a7.jpg) no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 36px;
}

.reg_queue_layout__content__3nzt3{
    margin:5%;
    display: grid;
    grid-gap: 50px;
    gap: 50px;
    grid-template-columns: repeat(3, 1fr) !important;
}

.reg_queue_layout__content__poly__fM0ED{
    min-width: 300px;
    min-height: 200px;
    padding: 0 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-shadow: 0px 0px 25px 3px rgba(0, 0, 0, 0.15);
    background-color: rgba(255, 255, 255, .9);
    border-radius: 10px;
    
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
      
    
    color: #219BD4;
    cursor: pointer;
    font-size: .9em;
    font-weight: 700;

    transition: all linear 50ms;
}

.reg_queue_layout__content__poly__fM0ED:active{
    -webkit-transform: scale(.9);
            transform: scale(.9);
}

@media(max-width:1024px){
    .reg_queue_layout__content__3nzt3{
        display: grid;
        grid-gap: 50px;
        gap: 50px;
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

@media(max-width:768px){
    .reg_queue_layout__content__3nzt3{
        grid-gap: 25px;
        gap: 25px;
        grid-template-columns: repeat(2, 1fr)!important;
    }
}

@media (max-width:640px){
    .reg_queue_layout__content__3nzt3{
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

/* @media(max-width:375px){
    .layout__content{
        width: 85%;
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

@media(max-width:320px){
    .layout__content{

        grid-template-columns: repeat(1, 1fr) !important;
    }

    .layout__content__poly{
        min-width: 230px;
        min-height: 200px;
    }

    .layout > h1 {
        font-size: 3rem !important;
    }
} */

/* .table-container{
    padding:10px;
}

.table {
    width: 100%;
    border-collapse: collaps|e;
    border-spacing:0 10px
}

.table-header{
   display: table;
}

.tr-header {
    padding: 10px;
    color: #414042B2; 
}

.table-body:nth-child(even) {
    background-color: rgba(196, 196, 196, 0.2);
} */


.queue_tr_body_style__2ee0m:nth-child(even){
    background-color: rgba(196, 196, 196, 0.2);
}

.queue_td_body_style__3KlHd{
    min-height: 50px;
    padding:10px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

/* utility */
thead > tr > td > div {
    padding:10px
}

.queue_form_control__1r4Ms{
    display: none;
}


.queue_form_control__search_input__GcMPV {
    background-color: #F7F7F7;
    border: none;
    border-radius: 10px;
    
    width: 100%;
    height: 40px;
    margin-right: 5%;
}

.queue_form_control__icon__75JhN{
    height: 100%;
    display: flex;
    align-items: center;

    position: absolute;
    right: 5%;
    
    z-index: 2;
}

@media (max-width: 768px) and (min-width:320px){
    .queue_table_container__sAYdp{
        border-collapse: collapse;
        overflow-x: auto;
    }

    .queue_form_control__1r4Ms{
        display: block;
    }
}
.display_tv_layout__2An8z {
  width: 100%;
  display: flex;
}

.display_tv_layout__left__2iYYy {
  width: 30%;
  height: 100%;
  padding: 5%;
  background-color: #219bd4;
  color: #ffffff;
}

.display_tv_layout__left__top__13ryc {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.display_tv_layout__left__top__13ryc > h1 {
  font-size: 6em;
  color: #ffffff;
  margin-bottom: 0.25em;
}

.display_tv_layout__left__top__13ryc > h2 {
  color: #ffffff;
}

.display_tv_layout__left__top__13ryc > span {
  font-size: 1.2em;
  font-weight: 800;
  color: #ffffff;
}

.display_tv_layout__left__middle__7KqV8 {
  padding: 8em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.display_tv_layout__left__middle__7KqV8 > h3 {
  font-size: 1.75em;
  font-weight: 300;
  color: #ffffff;
}

.display_tv_layout__left__bottom__2-w-v {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.display_tv_layout__left__bottom__2-w-v > h3 {
  font-size: 1.4em;
  font-weight: 300;
  color: #ffffff;
}

.display_tv_layout__left__bottom__2-w-v > span {
  max-width: 135px;
  height: 54px;
  font-size: 2.5em;
  font-weight: 600;
}

.display_tv_layout__right__1no6U {
  width: 70%;
}

.display_tv_layout__right__top__5uSxL {
  width: auto;
  height: 65%;
  background: url(/static/media/background_klinik.2017d72b.png) no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.display_tv_layout__right__bottom__5shM4 {
  padding: 3em;
  display: flex;
  justify-content: space-between;
}

.display_tv_layout__right__bottom__queue_container__1ZvO8 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.display_tv_layout__right__bottom__queue_container__order_number__1HPBT {
  width: 50px;
  height: 50px;
  background-color: #219bd4;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 1.75em;
  font-weight: 700;
}

.display_tv_layout__right__bottom__queue_container__queue_number__Uoj8g {
  font-size: 3em;
  font-weight: 600;
}

.display_tv_layout__right__bottom__queue_container__name__-KZqL {
  font-size: 1.3em;
  font-weight: 500;
}

.login_layout__caCNK {
    background-color: #FFFFFF;
    height: 100vh;
    display: flex;
}

.login_layout__left__15h5R {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login_layout__right__3YTXS {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

}

.login_layout__right__container__29Jz3 {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login_layout__right__container__background__1QAFt{
    display: none;
    position: absolute;
}

.login_layout__right__container__body__1s5ht {
    width: 280px;
}

.login_layout__right__container__body__1s5ht > h1 {
    font-weight: 700;
    color: #21D47E;
    margin: 40px 0;
    text-align: center;
}

.login_layout__right__container__form__label__VQBpK{
    text-align: center;
}

.login_layout__right__logo__1V-UU{
    background: url(/static/media/medisy.29ddaeea.png) no-repeat;
    background-size: contain;
    width: 500px;
    padding: 10px;
    margin: 10px 10px 10px 10px ;
    float: right;
}

.login_flex-col__1dIVS {
    display: flex;
    flex-direction: column;
}

.login_authInput__2qXUp{
    width: 280px;
    height: 40px;
    border-radius: 10px;
    border: 1px solid rgba(20, 155, 212, 0.5);
}
.login_authInput__2qXUp:hover{
    /* width: 280px;
    height: 40px;
    border-radius: 10px; */
    border: 1px solid #40a9ff;
}

.login_btnPrimary__mWetY {
    width: 100% !important;
    height: 40px !important;
    margin: 40px 0 !important;
    background-color: #0A7BCD !important;
    box-shadow: 0px 0px 23px -3px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
    font-weight: 700 !important;
    color: #FFFFFF !important;
}

.login_ant-form-vertical__2Zk-r .login_ant-form-item__1wTps {
    flex-direction: row !important;
}


@media(max-width: 768px){
    .login_layout__left__15h5R {
        display: none !important;
    }

    .login_layout__right__container__29Jz3{
        flex-direction: row;
    }

    .login_layout__right__container__background__1QAFt{
        display:block;
        z-index: 0;
        opacity: .15;
    }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.font-custom-1 { 
  color: rgba(65, 64, 66, 0.7)
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.tabs-medicine { 
  position: absolute;
  top: 30px;
  right: -20px;
}

.input-textfield { 
  border-radius: 7px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}
