 table{
     width: 100%;
 }
.tr_body_style:nth-child(even){
    background-color: rgba(196, 196, 196, 0.2);
}

.td_body_style{
    min-height: 50px;
    padding:10px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

/* utility */
thead > tr > td > div {
    padding:10px
}

.form_control{
    width: 30%;
}


.form_control__search_input {
    background-color: #F7F7F7;
    border: none;
    border-radius: 10px;
    
    width: 100%;
    height: 40px;
    margin-right: 0%;
    padding-right: 10%;
}

.form_control__icon{
    height: 100%;
    display: flex;
    align-items: center;

    position: absolute;
    right: 3%;
    
    z-index: 2;
}

.footer_container{
    padding:1%;
    display: flex;
    justify-content: space-between;
}

.select_section {
    display: flex;
    justify-content:center;
}

.select_limit{
    display: flex;
    align-items: center;


    margin: 0 15px;
    
}

.select_limit > select {
    border:none;
    border-radius: 5px;
    background-color: #f3f3f3;
    margin: 0 1%;
    padding:5px;
    height: 35px;
    outline-color: #1890ff

}

.select_limit > p {
    margin:0;
}

.container_pagination{
    width:30%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    width:25%;
    font-size:1.1em;
    list-style: none;
}
.container_pagination > li {
    padding: 0 10px;
    margin: 5px 10px;
}

.container_pagination > li > a{
    text-decoration: none;
    color: #000;
    font-size: 1.1em;
}

.container_pagination_active {
    background-color: #f3f3f3;
    border-radius: 10px;
}

@media (max-width: 768px) and (min-width:320px){
    .table_container{
        border-collapse: collapse;
        overflow-x: auto;
    }

    .form_control{
        display: block;
    }
}