.top-bar-background {
  background: #fff;
}

.logo {
  height: 70%;
  background: url("../header/medisy.png") no-repeat;
  background-size: contain;
  width: 12%;
  padding: 10px;
  margin: 10px 10px 10px 10px;
  float: right;
}

.menu {
  margin-left: 3%;
  width: 100%;
  /* background: #666; */
  float: left;
}

.data-table {
  margin: 0 auto;
  padding: 0;
}

.form-control {
  border: 1px solid rgba(20, 155, 212, 0.5);
  border-radius: 0.5em;
}

.lable-form-input {
  font-weight: bold;
}

.form-control-currency {
  width: 100%;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid rgba(20, 155, 212, 0.5);
}

.form-control-currency:focus {
  border-color: rgba(20, 155, 212, 0.5);
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.card-display {
  border-radius: 10px;
  margin: 10px;
}

.text-dashboard {
  font-size: 2em;
  text-align: center;
  margin-left: 50%;
}

.dividing-line {
  border: 1px solid #cacaca;
  background-color: #cacaca;
}

/* Start of Button Style */
.btn-primary {
  /* margin:5px; */
  padding: 0 25px;
  background: #27aae1;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  color: #ffff;
  border-color: transparent;
}
.btn-primary:hover {
  background: #27aae1;
  color: #ffff;
  opacity: 0.75;
  border-color: transparent;
}

.btn-primary:focus {
  background: #27aae1;
  color: #ffff;
  opacity: 0.75;
  border-color: transparent;
}

.btn-secondary {
  /* margin:5px; */
  padding: 0 25px;
  background: #21d47e;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  color: #ffff;
  border-color: transparent;
}
.btn-secondary:hover {
  background: #21d47e;
  color: #ffff;
  opacity: 0.75;
  border-color: transparent;
}

.btn-secondary:focus {
  background: #21d47e;
  color: #ffff;
  opacity: 0.75;
  border-color: transparent;
}

.btn-light {
  /* margin:5px; */
  padding: 0 25px;
  background: #ffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  color: #27aae1;
  border-color: transparent;
}
.btn-light:hover {
  background: #ffff;
  color: #27aae1;
  opacity: 0.75;
  border-color: transparent;
}

.btn-light:focus {
  background: #ffff;
  color: #27aae1;
  opacity: 0.75;
  border-color: transparent;
}

.btn-danger {
  /* margin:5px; */
  padding: 0 25px;
  background: #ff4d4f;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  color: #fff;
  border-color: transparent;
}
.btn-danger:hover {
  background: #ff7875;
  color: #fff;
  /* #d9363e */
  border-color: transparent;
}

.btn-danger:active {
  background: #d9363e;
  color: #fff;
  border-color: transparent;
}

.btn-danger:focus {
  background: #ff4d4f;
  color: #fff;
  opacity: 0.75;
  border-color: transparent;
}
/* End of Button Style */

/* start style of search box and add patient button */

.form-control__icon {
  height: 100%;
  display: flex;
  align-items: center;

  position: absolute;
  right: 5%;

  z-index: 2;
}

.form-control__search-input {
  background-color: #f7f7f7;
  border: none !important;
  border-radius: 10px;

  width: 200px;
  height: 40px;
  margin-right: 5%;
}

/* navbar */
.top_navbar_container {
  width: 100%;
  padding: 0 3%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top_navbar_container__left {
  width: 70%;
  display: flex;
  align-items: center;
}

.top_navbar_container__left_menu {
  display: none;
}

@media (max-width: 768px) and (min-width: 320px) {
  /* .form-control{
        display: none;
    } */

  .top_navbar_container__left_menu {
    display: block;
  }
}

/* end style of search box and add patient button */

/* when text input is hovering */
/* .ant-input:hover{
  border-color:rgba(0, 0, 0, 0);
} */
/* 
.ant-input:focus{
  border-color:rgba(0, 0, 0, 0.15);
} */

/* Customize selector antd (border within borders)*/
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #ffffff;
  border-radius: 7px;
}

/* when select is hovering*/
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #ffffff;
}

/* when select is in focus */
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border: 1px solid #ffffff;
  /* border-radius: 7px; */
}

/*
  * Start style for description
  */
.cus-description {
  width: 100%;
  margin-bottom: 20px;
}

.cus-description .ant-descriptions-view {
  border: unset !important;
}

.cus-description .ant-descriptions-view .ant-descriptions-row {
  border-bottom: unset !important;
}

.cus-description.ant-descriptions-bordered .ant-descriptions-item-label,
.cus-description.ant-descriptions-bordered .ant-descriptions-item-content {
  background-color: #fff !important;
  border-right: unset !important;
  padding: 10px 10px !important;
}

.cus-description.ant-descriptions-bordered .ant-descriptions-item-label {
  color: #414042f7 !important;
  font-size: 0.9em;
}

.cus-description.ant-descriptions-bordered .ant-descriptions-item-content {
  font-size: 1em;
  font-weight: 700;
}
/*
  * End style for description
  */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d1d1d1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a5a5a5;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d2d2d2;
}

.data_sidebar_container {
  display: flex;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.data_sidebar_container:hover {
  background-color: #219bd433;
}

.data_sidebar_container_active {
  background-color: #219bd433;
  display: flex;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.data_sidebar_container__icon {
  margin: 5px;
}

.data_sidebar_container > strong {
  padding-left: 10px;
  padding-right: 10px;
}

.data_sidebar_mobile_container {
  display: flex;
  align-items: center;
  margin: 5%;
  cursor: "pointer";
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.data_sidebar_mobile_container:hover {
  background-color: #219bd433;
}

.data_sidebar_mobile_container_active {
  display: flex;
  align-items: center;
  margin: 5%;
  cursor: "pointer";
  background-color: #219bd433;
}

@media (max-width: 1024px) {
  .ant-form-item-label > label {
    font-size: 12px;
  }

  .ant-select {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  /* Sidebar customize */
  .ant-layout-sider {
    display: none;
  }
}

@media (min-width: 1281px) {

  
  .btn-search-full {
    position: absolute;
    bottom: 70px;
    height: 32px;
    width: 20%;
    right: 50px;
  }

  .btn-search-full2 {
    position: absolute;
    bottom: 75px;
    height: 32px;
    width: 20%;
    right: 0;
    background-color: #21d47e;
  }

  .btn-search-full3 {
    position: absolute;
    bottom: 140px;
    height: 32px;
    width: 30%;
    right: 30px;
  }

  .data-table2 {
    bottom: 0.5vh;
  }

  .data-table {
    bottom: 6vh;
  }
}

.filter-data-table {
  width: 20%;
}